import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate, useParams } from "react-router-dom";
import { ExportOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../../components/Layout";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import noDataLogo from "../../../assets/images/no-data.png";
import color from "../../../theme/color";
import { customBaseUrl, httpDelete, httpGet } from "../../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import DeactivateUsers from "../../../components/Modal/User/DeactivateUsers";
import { useMatStyles } from "./style";
import { transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import CreateBulkForm from "../../../components/Modal/User/CreateBulkForm";
import SendSingleUserModal from "../../../components/Modal/TransferToNonWayaUser/SendSingleUser";
import BulkTransfer from "../../../components/Modal/TransferToNonWayaUser/BulkTransfer";
import NonWayaWebForm from "../../../components/Modal/TransferToNonWayaUser/WebForm";
import { useTheme } from "styled-components";
import ExportData from "./exportData";
import TemporalPeriodModal from "../../../components/Modal/TransferToNonWayaUser/TemporalPeriod";
import { FilterList, Settings } from "@mui/icons-material";
import ReferralSettings from "./settings";
import AssignCode from "./assignCode";
import RefferalWebForm from "./webForm";
import UploadExcel from "./excelUpload";
import SingleUserBonus from "./singleUserBonus";
import moment from "moment";
import { CSVLink } from "react-csv";

const Referrals = () => {
  const theme = useTheme();
  const { id: referalCode } = useParams();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showTransferExcel, setShowTransferExcel] = useState(false);
  const [showTransferForms, setShowTransferForms] = useState(false);
  const [showAssignCode, setShowAssignCode ] = useState(false)
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "referralUser",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [retrievedTransfers, setRetrievedTransfers] = useState(0);
  const [nonRetrievedTransfers, setNonRetrievedTransfers] = useState(0);
  const [allReferrals, setAllreferrals] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const [filterValue, setFilterValue] = useState(false);
  const [showMultiple, setShowMuliple] = useState(false);
  const [showExcel, setShowExcel] = useState(false);
const [selectedRow, setSelectRow ] = useState([]);
  const [ showSendSingle, setShowSendSingle] = useState(false);
  const [allFilteredreferrals,setAllFilteredreferrals] = useState([]);
  const [fileteredMeta, setFilteredMeta] = useState(0);
  const [stat, setStat] = useState([]);
  const getTransactions = async (page, size) => {
    setAllreferrals([]);
    showLoader();
    const pages = page ?? pagination.current;
    const limit = size ? size : pagination.pageSize;
    console.log('pages',pages)
    let request = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/admin/fetchAllReferralUsers?field=referralCode&pageNo=${pages+1}&pageSize=${limit}&value=${referalCode}`
    );
      console.log(request.data);
    if (request.status) {
      console.log('dtaa',request.data.content);
      setAllreferrals(request.data.content);
      setAllTransfers(request.data.totalElements);
    }
    hideLoader();
  };

  const getTemporalReversalDays = async () => {
    
    let request = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/config`
    );
      console.log(request.data);
    if (request.status) {
      setReveversalDays(request.data);
    }
    hideLoader();
  };


  const getStat = async () => {
    
    let request = await httpGet(
      `${customBaseUrl.referralUrl}/api/v1/admin/analysis`
    );
      console.log(request.data);
    if (request.status) {
      setStat(request?.data);
    }
    hideLoader();
  };

  useEffect(() => {
    getStat();
   getTransactions();
    // getTemporalReversalDays();
}, []);



const getFilteredReferral = async (page, size) => {
  setFilterValue(true)
  setAllFilteredreferrals([]);
  showLoader();
  const pages = page==0 ? page : pagination.current;
  const limit = size ? size : pagination.pageSize;
  
  let request = await httpGet(
    `${customBaseUrl.referralUrl}/api/v1/admin/filter-users-with-referrals?page=${pages}&size=${limit}&value=${search?.searchText}`
  );
    console.log(request.data);
  if (request.status) {
    console.log('dtaa',request.data.users);
    setAllFilteredreferrals(request.data.users);
    setFilteredMeta(request.data.totalElements);
  }
  hideLoader();
};
 

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };



  var searchInput = useRef({});
  const columns =  React.useMemo(() =>  [
    { field: 'name',
    headerName: 'Referrer Name',
    width: 200 ,
    headerClassName: styles.tableHeader,

  },
    { field: 'referenceCode',
    headerName: 'Referrer Referral Code',
    width: 200 ,
    headerClassName: styles.tableHeader,

  },
  { field: 'rcode',
    headerName: 'Referral Referral Code',
    width: 200 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row?.referral ? row?.referral.referralCode : 'N/A'


  },
 
  { field: 'referallname',
    headerName: 'Referral Name',
    width: 200 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row}) => row?.referral ? row?.referral?.name : 'N/A'

  },
  { field: 'phoneNumber',
  headerName: 'Referrer Phone',
  width: 250 ,
  headerClassName: styles.tableHeader,

},
{ field: 'email',
  headerName: "Referrer Email",
  width: 200,
  headerClassName: styles.tableHeader,

  },
     
  { field: 'RphoneNumber',
  headerName: 'Referral Phone',
  width: 250 ,
  headerClassName: styles.tableHeader,
  renderCell: ({row}) => row?.referral ? row?.referral.phoneNumber : 'N/A'

},
{ field: 'remail',
  headerName: "Referral Email",
  width: 200,
  headerClassName: styles.tableHeader,
  renderCell: ({row}) => row?.referral ? row?.referral.email : 'N/A'


  },
  
{ field: 'createdAt',
  headerName: "Referrer Account Creation Date",
  width: 200,
  headerClassName: styles.tableHeader,

  },

  { field: 'rcreatedAt',
  headerName: 'Referral Account Creation Date',
  width: 250 ,
  headerClassName: styles.tableHeader,
  renderCell: ({row}) => row?.referral ? row?.referral.createdAt : 'N/A'


},

  ]);

  const filterOptions = [
    "Transactions Done After Referral",
    "Bonus Earned by Users"
  ];
  const handleFilterDropdown = (item) => {
    if (item === "Transactions Done After Referral") navigate('/referral/no_of_transactions');
    if (item === "Bonus Earned by Users") navigate('/referral/bonus_earned');

    //if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const bulkOptions = [
    "Multiple Web",
    "Excel Upload"
  ];
  const handleBulkDropdown = (item) => {

    if (item === "Multiple Web") setShowMuliple(true);
    if (item === "Excel Upload") setShowExcel(true);

    //if (item === "Send Using Webforms") setShowTransferForms(true);
  };

  const exportData = allReferrals.map((el) => {
      return {
        name: el.name,
        "Referrer Referral Code": el?.referenceCode,
        "Referral Referral Code": el?.referral ? el?.referral.referralCode : '',
        "Referrer Name": el.name,
        "Referral Name": el?.referral ? el?.referral.name : '',
        "Referrer Phone": el?.phoneNumber?.toString(),
        "Referrer Email": el.email,
        "Referral Phone": el?.referral ? el?.referral.phoneNumber?.toString() : '',
        "referrer Account Creation Date": el.createdAt,
        "referral Account Creation Date": el?.referral ? el?.referral.createdAt : '',
        "Status": el.active,
        'Account Type': el?.corporate ? 'Corporate' : 'Personal', 
      };
})
  
  return (
    <Layout>

<div style={tool}>
      {/*<select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x?.field !== ("earnings"))
            .map((header) => (
              <option value={header.field}>
                {transformName(header.headerName)}
              </option>
            ))}
            </select>*/}
        {/* <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="users referrals"
            onChange={(event) => {
              setFilterValue(false)
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          
        </div> */}
        {/* <button style={{margin: 10}} onClick={()=>{getFilteredReferral(1,10)}} className="btn btn-primary">
          search
        </button> */}
        {/* <div>
      <AntDropdown
            dropDownOptions={filterOptions}
            handleDropdownAction={handleFilterDropdown}
          >
            <FilterList/>
          </AntDropdown>
      </div> */}

        <Row type="flex" justify="end" align="middle">
      
            <Button
            variant="contained" 
            style={{marginRight:20}}
            // onClick={()=>setShowStatement(true)}
          >
            <CSVLink
          data={exportData}
          style={{            
            display: "flex",
            alignItems: "center",
            textDecoration:"none"
          }}
          filename={`Referrals.csv`}
          onClick={(event, done) => {
             //event.preventDefault();
              // handleFilterOperation();
            //done()           
          }}
        >
          DOWNLOAD AS EXCEL
        </CSVLink>
          </Button> 
            
        </Row>
      </div>

      {filterValue?
        <Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Referral Code</p>
          <h5 className="text-left">{search?.searchText}</h5>
        </Card.Grid>
        <Card.Grid hoverable={false} style={gridStyle} className="sm-1">
          <p>Total Signup with Referral Code</p>
          <h2 className="text-left">{fileteredMeta}</h2>
        </Card.Grid>
        
      </Card>
      :<Card style={{ background: "transparent" }}>
        <Card.Grid style={gridStyle} className="mr-2">
          <p>Total Referrals</p>
          <h2 className="text-left">{allTransfers ?? 0}</h2>
        </Card.Grid>
        {/* <Card.Grid style={gridStyle} className="mr-2">
          <p>Total Aggregators</p>
          <h2 className="text-left">{stat?.totalReferralCount}</h2>
        </Card.Grid> */}
        {/*<Card.Grid hoverable={false} style={gridStyle} className="sm-1">
          <p>Total Payment Disbursed</p>
          <h2 className="text-left">{retrievedTransfers}</h2>
        </Card.Grid>*/}
        
      </Card>
        }

      <Grid
      getRowId ={row=>row.id}
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allReferrals.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
        getRowId={row=>row.userId}
         className={styles.table}
         density="comfortable"
        rows={allReferrals }
      /*}  rows={allReferrals?.filter((request) =>
          request[search?.searchBy]
            ?.toLowerCase()
            ?.includes(search?.searchText?.toLowerCase())
      )}*/
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={ allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
    
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allReferrals}  />
      }
      {
        showEditDays && 
        <ReferralSettings getTemporalReversalDays={getTemporalReversalDays} showModal={showEditDays} hideModal={setShowEditDays} title="Edit No of Days" className={styles.modal} />
      }
      {
        showAssignCode && 
        <AssignCode showModal={showAssignCode} hideModal={setShowAssignCode} title="Assign Refferral Code" className={styles.modal} />
      }
      {
        showMultiple && <RefferalWebForm showModal={showMultiple} hideModal={setShowMuliple} />
      }
       {
        showExcel && <UploadExcel showModal={showExcel} hideModal={setShowExcel} />
      }
        {
        showSendSingle && <SingleUserBonus showModal={showSendSingle} hideModal={setShowSendSingle} selectedData={selectedRow} />
      }
    </Layout>
  );
};

const gridStyle = {
  width: "40%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "50px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default Referrals;