import React, { useState, useEffect } from "react";
import Layout from "../../Layout";
import useMatStyles from "./style";
import { Grid as MatGrid, Paper, Button as MatButton, Filt } from "@mui/material";
import { Button, Input, Space } from "antd";
import { ExitToAppOutlined, Filter, Filter1Outlined, FilterList, InputTwoTone } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import CopyToClipboardComponent from "../../CopyToClipBoard";
import { customBaseUrl, httpGet, httpPost } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import AntDropdown from "../../CustomDropdown/AntDropdown";
import Swal from "sweetalert2";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { currencyFormat, transformName } from "../../../utils/helper";
import FilterTransactions from "./modals/filter";
//import { Modal } from 'antd';
import ExportTrans from './modals/export'
import Exp from "../../../assets/images/export.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ProfileContext } from "../../../store/context/ProfileContext";
import { useContext } from "react";
import ViewTransaction from "../../../views/Transaction/AllTransaction/modals/ViewTransaction";

let initial = {
  searchCategory: "",
  searchType: "",
  searchAccount: "",
  searchVendo: "",
  searchStatus: "",
  searchAmount: 1000,
  searchDate: [0, 0]

}
const TransactionHistory = () => {
  const { id: userId } = useParams();
  const { profile } = useContext(ProfileContext);
  const [transactions, setTransactions] = useState([]);
  const styles = useMatStyles();
  const statistics = ["Total Deposit", "Total Withdrawal", "Total Balance", "Total Revenue"];
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [metaData, setMetaData] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [showTransactionReceiptModal, setShowTransactionReceiptModal] = useState(false);
  const [filtered, setFiltered] = useState(true)
  const [findText, setFindText] = useState(false)
  const [transaction, setTransaction] = useState({});
  const [totalCredit, setTotalCredit] = useState(0)
  const [totalDebit, setTotalDebit] = useState(0)
  const [totalCreditDebit, setTotalCreditDebit] = useState(0)
  const [showExport, setShowExport] = useState(false);
  const [wallets, setWallets] = useState([])
  const [account, setAccount] = useState('');
  const [defaultWallet, setDefaultWallet] = useState('')
  const [analysis, setAnalysis] = useState([]);

  // const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({
    searchBy: "partTranType",
    searchText: "",
  });
  const [searchData, setSearchData] = useState(initial)
  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };
  const handleChangeRowsPerPage = async (value) => {
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const viewTransactions = (row) => {
    setTransaction(row);
    setShowTransactionReceiptModal(true);
  };

  const handleReverseTransaction = async (row) => {
    //return alert(JSON.stringify(row)); 
    showLoader();
    let reverseData =
    {
      tranCrncy: row.tranCrncyCode,
      tranDate: row.tranDate,
      tranId: row.tranId,
      tranAmount: row?.tranAmount
    }
    /*
    const response = await httpPost(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/reverse`,
      reverseData
    );*/



    let d = new Date();
    const res = await httpPost(`${customBaseUrl.logUrl}/api/v1/requestlog/create`,
    {
      payload: JSON.stringify(reverseData),
      "requestId": `REQ${d.getTime()}`,
      "requestType": "reversal",
      customerName: row?.senderName
    } )
    
    if (res.status === true) {
      hideLoader();
      Swal.fire({
        title: "Success",
        text: res.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      //  await getTransactions(pagination.current, pagination.pageSize);
    }
    else {
      hideLoader();
      Swal.fire({
        title: "Error",
        text: res.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  const reverseTransaction = (row) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to reverse this transaction?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reverse it!",
    }).then((result) => {
      if (result.value) {
        handleReverseTransaction(row);
      }
    });
  };
  const dropDownOptions = ["View receipt", "Reverse"];
  const handleDropdownAction = (item, row) => {
    if (item === "View receipt") viewTransactions(row);
    if (item === "Reverse") reverseTransaction(row);
  };
  const columns = React.useMemo(() => [
    {
      field: "paymentReference",
      headerName: "Reference ID",
      width: 300,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { paymentReference = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {paymentReference.length > 40
              ? paymentReference.slice(0, -25) + "..."
              : paymentReference}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.tranId.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.paymentReference}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "tranId",
      headerName: "Transaction ID",
      width: 250,
      headerClassName: styles.tableHeader,
      editable: false,
      expandable: true,
      pinnable: true,
      renderCell: ({ row }) => {
        const { tranId = "" } = row;
        return (
          <div style={{ display: "flex" }}>
            {tranId.length > 40
              ? tranId.slice(0, -25) + "..."
              : tranId}

            <span>
              <CopyToClipboardComponent
                style={{
                  fontSize: row.tranId.length > 20 ? "12px" : "14px",
                }}
                showText={false}
                text={row.tranId}
              />
            </span>
          </div>
        );
      },
    },
    {
      field: "tranDate",
      headerName: "Date",
      width: 100,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "senderName",
      headerName: "Sender's Name",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "acctNum",
      headerName: "Sender's Account",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "receiverName",
      headerName: "Receiver's Name",
      width: 150,
      editable: false,
      headerClassName: styles.tableHeader,
    },
    {
      field: "tranCategory",
      headerName: "Category",
      description: "Category Column",
      sortable: true,
      width: 200,
      headerClassName: styles.tableHeader,
    },
    
    {
      field: "tranAmount",
      headerName: "Amount",
      width: 100,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },
    {
      field: "tranNarrate",
      headerName: "Narration",
      width: 200,
      editable: false,
      sortable: true,
      description: "Amount Column",
      type: "number",
      headerClassName: styles.tableHeader,
    },

    {
      field: "partTranType",
      headerName: "Type",
      width: 120,
      editable: false,
      sortable: true,
      description: "Status Column",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => {
        return (
          <div>
            <span
              style={{
                color: `${row.partTranType === "C" ? "#27AE60" : "#EB5757"}`,
              }}
            >
              {row.partTranType === "C" ? "Credit" : "Debit"}
            </span>
          </div>
        );
      },
    },
   
    {
      field: "id",
      headerName: " ",
      width: 80,
      align: "right",
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={dropDownOptions}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),
    },
  ]);
  const getTransactions = async (page, size) => {
   // showLoader();
   
  const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    const response = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/find/transactions/${account?account:defaultWallet}?page=${pages}&size=${limit}`
    );
    if (response.status === true) {
      console.log("Aall trans",response?.data?.content )
     setTransactions(response?.data?.content);
      //setFiltered(response.data.content)
      //setMetaData(response?.data?.content.length);
      hideLoader();
    } else {
      hideLoader();
    }
  };

  const getAnalysis = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/analysis/transaction/${userId}`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setAnalysis(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalDebit(res.data.amount);
      hideLoader();
    } else {
      hideLoader();
    }

  };

  const getTotalDebitCredit = async () => {
    showLoader();


    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/transaction/total-credit-debit`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      setTotalCreditDebit(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };
  const getWallets = async () => {
    showLoader();

    const res = await httpGet(
      `${customBaseUrl.walletUrl}/api/v1/wallet/admin/user/accounts/${userId}`
    );

    if (res.status === true) {
      //setFiltered(response.data.content)
      const dWallet = res.data?.filter(e=>e?.walletDefault)
      if(dWallet.length>0){
        console.log('df', dWallet[0].accountNo)
        setDefaultWallet(dWallet[0].accountNo)
      }
      setWallets(res.data);
      hideLoader();
    } else {
      hideLoader();
    }

  };
  // const showModal = () => {/temporal-service/api/v1/wallet/admin/user/accounts/1939
  //   setVisible(true);
  // };
  // const handleCancel = () => {
  //   setVisible(false);
  // };


  useEffect(() => {
    getWallets();
    getTransactions();
    getAnalysis();
    getTotalDebit();
    getTotalDebitCredit();
  }, []);

  useEffect(()=>{
    getTransactions();
  },[account, defaultWallet])

  const handleShowFilter = () => {
    setShowFilter(true)
    setSearch({ ...search, searchText: '' })
  }

  const handleFilter = () => {
    console.log('searchData', searchData);
    //getTransactions();
    setFiltered(false)
    setShowFilter(false)
    setSearch({ ...search, searchText: '' })



  }
  const handleClearFilter = () => {
    //  return alert(JSON.stringify(transactions))
    setSearch({ ...search, searchText: '' })
    setSearchData(initial)
    setShowFilter(false)
    setFiltered(true)
  }

  const handleSearch = () => {
    if (!search.searchText) {
      return;
    }

    //alert(JSON.stringify(transactions.filter(req=>req?.paymentReference.toLowerCase().includes(search?.searchText.toLowerCase())||req?.tranCategory.toLowerCase().includes(search?.searchText.toLowerCase()))))


  }

  const SendUserOptions = [
    <div><Input placeholder="search" onChange={(e) => alert(e.value.target)} /></div>,
    "Send to Single User",
    "Send Using Webforms",
    "Upload Excel File to Send",
  ];
  return (
    <Layout>
      <div>
       
           <select onChange={(e)=>setAccount(e.target.value)}>
            <option>Select wallet</option>
           {wallets?.map(e => 
              <option value={e?.accountNo}>
                {e?.description} - {e?.accountNo} -NGN{e?.clr_bal_amt}
              </option>)}
        </select>
      </div>
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >
        {statistics.map((item, index) => (
          <Paper elevation={1} className={styles.card}>
            <div className={styles.dibot}>
              {" "}
              <span style={{ float: "left" }} className={styles.diTop}>
                {item}
              </span>
              {item === 'Total Balance' ? <span className={styles.money}><br/>{currencyFormat(analysis?.totalBalance ?? 0)} </span> :
                item === 'Total Deposit' ? <span className={styles.money}> <br/>{currencyFormat(analysis?.totalDeposit ?? 0)}</span> :
                item === 'Total Withdrawal' ? <span className={styles.money}> <br/>{currencyFormat(analysis?.totalWithdrawal?? 0)}</span> :

                  <span className={styles.money}> <br/>{currencyFormat(analysis?.totalrevenue ?? 0)}</span>}
            </div>
          </Paper>
        ))}
        </MatGrid>
      <div className="d-flex justify-content-between">
        <div style={tool} class="col-md-5">

          <div className={styles.searchRow}>
            <Input
              className={styles.searchInput}
              placeholder="Search Transactions"
              value={search.searchText}
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              prefix={<SearchOutlined className={styles.searchIcon} />}
            />
          </div>

       
        </div>
            <div >
          
          <MatButton
            //className="update-button-1"
            onClick={() => setShowExport(true)}
            variant="contained" 
            color="success"
          >
            <img src={Exp} alt="export" />
            <div>Export Data</div>
      </MatButton>
        </div>

      </div>
        
      <MatGrid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={styles.matGrid}
      >

        <div style={{ height: 800, width: "100%" }}>
          {transactions.length>0?<DataGrid
            className={styles.table}
           rows={search.searchText ? transactions.filter(req => req?.tranId.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.senderName.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.paymentReference?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranType?.toLowerCase()?.includes(search?.searchText.toLowerCase()) || req?.tranDate.includes(search?.searchText) || req?.tranAmount === (search?.searchText.toLowerCase())) :
               transactions    }
            density="comfortable"
            columns={columns}
            page={pagination.current}
            paginationMode="server"
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}
          />:<div>
              No Transaction Found
            </div>}
        </div>
      </MatGrid>
      {showTransactionReceiptModal && (
        <ViewTransaction
          showModal={showTransactionReceiptModal}
          setShowModal={setShowTransactionReceiptModal}
          transaction={transaction}
        />
      )}

      {showFilter && (
        <FilterTransactions
          showModal={showFilter}
          hideModal={setShowFilter}
          clearFilter={handleClearFilter}
          searchData={searchData}
          setSearchData={setSearchData}
          handleFilters={handleFilter}
        />)
      }



      {showExport ? (
        <ExportTrans showModal={showExport} hideModal={setShowExport} dataList={transactions} accountNo={account} />
      ) : (
        ""
      )}



    </Layout>
  );
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};

export default TransactionHistory;
