import {
  ExportOutlined,
  PlusOutlined,
  SearchOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Input, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/Layout";
import { useMatStyles } from "./style";
import CustomTable from "../../../components/CustomTable/index3";
import styled from "styled-components";
import {
  Box,
  TableCell,
  TableRow,
  Grid as MatGrid,
  Paper,
} from "@mui/material";
import { getComparator, stableSort } from "../../../utils/helper";
import { customBaseUrl, httpGet, httpPut } from "../../../store/http";
import { hideLoader, showLoader } from "../../../utils/loader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import AntDropdown from "../../../components/CustomDropdown/AntDropdown";
import ExportData from "../../WayaOfficial/modal/exportData";
import ViewDocModal from "../../../components/Modal/ViewDoc";
import Swal from "sweetalert2";
import KycCacPage from "./cacUserTier";
import Reason from "./reason";

const Container = `styled.div`;



const Tier =({id})=>{
  const [tier, setTier] = useState()

  useEffect(async()=>{
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/fetch-status/${id}`
    );
      if  (res.status){
        hideLoader();
       // alert(res?.data?.status[0]?.tiers?.tiers)
         setTier(res?.data?.status[0]?.tiers?.tiers);
      }else{
        hideLoader();
        console.log(res.message);
      }
  },[])

  return(
    <h6>{tier}</h6>
  )
}

const KycMainPage = () => {
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState({
    order: "asc",
    orderBy: "businessName",
  });
  const [search, setSearch] = useState({
    searchBy: "businessName",
    searchText: "",
  });
  const [metaData, setMetaData] = useState(0);
  const [pagination, setPagination] = useState({ current: 0, pageSize: 10 });
  const [pendingData, setPendingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [stat, setStat] = useState([])
  const [tierStat, setTierStat] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [searchField, setSearchField] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDate, setFilteredDate] = useState([]);
  const [showViewDocModal, setShowViewDocModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [field, setField] = useState(null);
  const [value, setValue] = useState(null);
  const styles = useMatStyles();
  const [isCac, setIsCac] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState('');



  const dropDownOptions = ["Update KYC", "View Doc"];
  const handleDropdownAction = (item, row) => {
    if (item === "Update KYC") navigate(`/users_kyc/${row.customerId}`);
    if(item === "VIEW DOCUMENT"){
        setViewData({
          url: row?.attachment,
          title: row?.dataType,
        });
        setShowViewDocModal(true);
    }
    if(item === "APPROVE") approveReject(row?.id, "APPROVED");
    if(item === "REJECT") {
      setSelectedId(row?.id);
      setShowReason(true);
      // approveReject(row?.id, "REJECTED");
    }
    if(item === "VIEW ALL KYC") navigate(`/users_kyc/${row.customerId}`)
  };

  const approveReject = async (id, status) => {
    const res = await httpPut(
      `${customBaseUrl.kycUrl}/api/v1/admin/kyc/approveOrReject/${id}?reason=${reason}&status=${status}`
    );
      if  (res.status){
        hideLoader();
        Swal.fire('Done', res?.message, 'success')
        getKycStat();
        getUsers();
        setReason('');
      }else{
        hideLoader();
        Swal.fire('error', res?.message)
        console.log(res.message);
        setReason('');
      }
  }
  const columns = [
  
    { field: 'customerId',
      headerName: 'Customer Id',
      width: 250 ,
      headerClassName: styles.tableHeader

    },
    { field: 'name',
      headerName: 'Full Name',
      width: 250 ,
      headerClassName: styles.tableHeader

    },

    { field: 'createdBy',
      headerName: "Email",
      width: 250 ,
      headerClassName: styles.tableHeader,

    },
    /*{ field: 'customerPhoneNumber',
    headerName: "Phone Number",
    width: 200 ,
    headerClassName: styles.tableHeader,

    },*/
    { field: 'kycTier',
    headerName: "Tier Level",
    width: 150 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=> row?.kycTier?.name.replace('_',' ')

       
    },
    { field: 'dataType',
    headerName: "Data Type",
    width: 150 ,
    headerClassName: styles.tableHeader,
    // renderCell: ({row})=> row?.kycStatus?.processed==='true'?'Completed':row?.kycStatus?.processed==='false'?'Under Review':'Not Completed'

       
    },
    { field: 'status',
    headerName: "Status",
    width: 150 ,
    headerClassName: styles.tableHeader,
    renderCell: ({row})=> row?.status.replace('_',' ')
       
    },
    
  
    {
      field: "",
      headerName: "Action ",
      width: 80,
      align: "right",
      headerClassName: styles.tableHeader,
      renderCell: ({ row }) => (
        <AntDropdown
          dropDownOptions={["VIEW DOCUMENT", "APPROVE", "REJECT", "VIEW ALL KYC"]}
          handleDropdownAction={(item) => handleDropdownAction(item, row)}
          userId={row}
          isArrow
        />
      ),

    },
     ];  


  const handleChangePage = async (newPage) => {
    setPagination({ ...pagination, current: newPage });
    // await getUsers(newPage, newPage, pagination.pageSize);

  };
  const handleChangeRowsPerPage = async (e) => {
    console.log(e)
    setPagination({ ...pagination, current: 1, pageSize: e });
  //await getUsers(e,1, e);
  };
  const handleOpenTierLevels = (Id) => {
    navigate(`/users_kyc/${Id}`);
  };
  const handleRequestSort = (event, property) => {
    const { order, orderBy } = orderData;
    const isAsc = orderBy === property && order === "asc";
    setOrderData(
      isAsc
        ? { order: "desc", orderBy: property }
        : { order: "asc", orderBy: property }
    );
  };

  const getPendingKYC = async () => {
    showLoader();
    const res = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/count/totalPendingStatus`
    );
      if  (res.status){
        hideLoader();
        setPendingData(res.data);
      }else{
        hideLoader();
        console.log(res.message);
      }
   

    return [];
  };

  const getKycStat = async() =>  {
    let res = await httpGet(`${customBaseUrl.kycUrl}/api/v1/admin/kyc/analysis`)
    if(res.status){
      setStat(res.data)
    }
  }
  const getTierStat = async() =>  {
    let res = await httpGet(`${customBaseUrl.kycUrl}/kyc/tier/count-users-by-tier`)
    if(res.status){
      setTierStat(res.data)
    }
  }

  function clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  const getUsers = async (e,page, size) => {
    e?.preventDefault();
    console.log('page', page)
    console.log('size', size)

    showLoader();
    const pages = page === 0 ? 0 : page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    /*
    let request = await httpGet(
      `${customBaseUrl.kycUrl}/kyc/list/all/paginated?page=${pages}&size=${limit}`
    );
    if (request?.status) {
      console.log(request)
      hideLoader();
      setUsersData(request?.data?.users)
      setMetaData(request?.data?.totalItems);

      
    }else{
      console.log(request)
      hideLoader();

    }
    */
    const response = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/admin/kyc/customerKycData/all?field=${field??'all'}&pageNo=${pages + 1}&pageSize=${limit}&value=${value??'all'}`
    );
    if (response?.code === 200) {
      //console.log(request)
      hideLoader();
      setUsersData(response?.data?.content)
      setMetaData(response?.data?.totalElements);

      
    }else{
      console.log(response)
      hideLoader();

    }
    //const filter = clean(filteredData);
    /*if (response.status === true) {
      console.log('filter', filteredData);
      const filtered = response?.data?.content?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      setTransactions(filtered);
      //setFiltered(response.data.content)
      setMetaData(filtered?.length);
      //  console.log('filter', fil)
      
      hideLoader();
    } else {
      NotificationManager.error(response?.message)
      setTransactions([]);
  
      hideLoader();
    }
    */

  };

  /*const getKyc = async (e) => {
    e.preventDefault();
    showLoader();
    const response = await httpGet(
      `${customBaseUrl.kycUrl}/api/v1/admin/kyc/customerKycData/all?field=status&pageNo=1&pageSize=5&value=APPROVED`
    );
    
    const filter = clean(filteredData);

    if (response.status === true) {
      console.log('filter', filteredData);
      const filtered = response?.data?.transaction?.filter(e=>  {
        //  console.log(filteredData)
        return Object.keys(filter).every(key => {
          return filter[key] === e[key];
        });
          //if( e?.tranCategory?.includes(filteredData?.tranCategory) && e?.partTranType?.includes(filteredData?.partTranType) ) return e
        
      });
      setTransactions(filtered);
      //setFiltered(response.data.content)
      setMetaData(filtered?.length);
      //  console.log('filter', fil)
      
      hideLoader();
    } else {
      NotificationManager.error(response?.message)
      setTransactions([]);
  
      hideLoader();
    }
  };*/

  useEffect(() => {
    getKycStat()
    getPendingKYC();
    getTierStat();
  }, []);
  useEffect(()=>{
    getUsers()
  },[pagination?.pageSize, pagination.current]);

  // Avoid a layout jump when reaching the last page with empty rows.
  
const category = ['customerId','status','name','email','phoneNumber']
const sCategory = ['INITIATED', 'UNDER_REVIEW', 'PENDING', 'APPROVED', 'REJECTED']

  return (
    <Layout>
      <div>
        <button className={`btn ${!isCac ? 'btn-primary': ''}`} onClick={()=>setIsCac(false)}>Non CAC Users</button>
        <button className={`btn ${isCac ? 'btn-primary': ''}`} onClick={()=>setIsCac(true)}>CAC Documents</button>
      </div>
      {isCac ? (
        <KycCacPage/> 
      ) : (
      
      <>
      <Container>

        <div className={styles.searchRow}>
          {/*<select
            className={styles.selectType}
            defaultValue="disabled"
            onChange={(event) => {
              setSearch({ ...search, searchBy: event.target.value });
            }}
          >
            <option value="disabled" disabled>
              Search By...
            </option>
          
          </select>
          <div className={styles.searchRow}>
            <Input
              className={styles.searchInput}
              placeholder="Search data by Name, Email or Date"
              onChange={(event) => {
                setSearch({ ...search, searchText: event.target.value });
              }}
              prefix={<SearchOutlined className={styles.searchIcon} />}
            />
          </div>*/}
         {/*} <div>
          <Button
              size="medium"
              style={{ background: "#27AE60", fontSize: "10px", color: "#fff" }}
              onClick={() => setShowExport(true)}
            >
              <ExportOutlined />
              Export Data
      </Button>
        </div>*/}
          {/*<div className={styles.grow} />

          <Button
          onClick={() => navigate("/kyc/kyc_settings")}
           className={styles.updateButton} size="middle">
            Update User Tier Level
          </Button>*/}
        </div>
        <div className={styles.dashboardItem}>
          {/*<div className={styles.dashItem}>
            <div className={styles.diTop}>Total Number of Users</div>
            <div className={styles.dibot}>{stat?.totalUsers}</div>
          </div>
          <div className={styles.dashItem}>
            <div className={styles.diTop}>Tier Levels</div>
            <div className={styles.dibot}>{stat?.totalTierLevels}</div>
          </div>*/}
          <div className={styles.dashItem}>
            <div className={styles.diTop}>KYC Pending for Approval</div>
            <div className={styles.dibot}>{stat?.pending}</div>
          </div>
          <div className={styles.dashItem}>
            <div className={styles.diTop}>APPROVED</div>
            <div className={styles.dibot}>{stat?.approved}</div>
          </div>
          <div className={styles.dashItem}>
            <div className={styles.diTop}>REJECTED</div>
            <div className={styles.dibot}>{stat?.rejected}</div>
          </div>
        </div>
        <MatGrid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
           
          <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 1 Users</span><span style={{float: 'right'}}><br/> {stat?.tier1}</span></div>
          </Paper>
          <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
          <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 2 Users</span><span style={{float: 'right'}}><br/> {stat?.tier2}</span></div>
          </Paper>
          <Paper elevation={4} style={{ padding: "12px", width:'17%' }}>
          <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 3 Users</span><span style={{float: 'right'}}><br/> {stat?.tier3}</span></div>
          </Paper>
          <Paper elevation={4} style={{ padding: "12px" , width:'17%'}}>
          <div className={styles.dibot}> <span style={{float:'left'}} className={styles.diTop}>Tier 4 Users</span><span style={{float: 'right'}}> <br/>{stat?.tier4}</span></div>
          </Paper>
        
        </MatGrid>

        <div>
        <Button 
        variant="contained" 
        style={{width: '100%', marginBottom: 10}}
        onClick={()=>setSearchField(!searchField)}>Click to {searchField ? 'hide': 'show' } search field</Button>
      </div>
      <div hidden={!searchField}>
        <form onSubmit={getUsers}>
      <div class="row">
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Status</span>
        <select class="form-select" id="inputGroupSelect01" required
          onChange={(e)=>{ 
            setField(e.target.value);      
            setFilteredData({...filteredData, status: e?.target?.value})
            setPagination({...pagination, current: 0})
          
          }
          }
        >
          <option selected>Choose...</option>
          {
            category?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ').toUpperCase()}</option>
          )
          }
        </select>
      </div>
      {field === 'status'?<div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Value</span>
        <select class="form-select" id="inputGroupSelect01" 
          onChange={(e)=>{
            setValue(e.target.value)      
            setFilteredData({...filteredData, status: e?.target?.value})
            setPagination({...pagination, current: 0})
          
          }
          }
        >
          <option selected>Choose...</option>
          {
            sCategory?.map((e,i)=>
            <option key={i} value={e}>{e.replace('_',' ')}</option>
          )
          }
        </select>
      </div>:
      <div class="input-group mb-3 col">
        <span class="input-group-text" id="basic-addon3">Value</span>
        <input  
        onChange={(e)=>setValue(e.target.value)}
        class="form-select" id="inputGroupSelect01" />
      </div>
        }
      {/*<div className="input-group mb-3 col">
          <span className="input-group-text">Transaction Type</span>
          <select class="form-select" id="inputGroupSelect02"
              onChange={(e)=>setFilteredData({...filteredData, partTranType: e?.target?.value})}
          >
          <option selected>Choose...</option>
          <option value={''}>ALL</option>
          <option value="D" >DEBIT</option>
          <option value="C">CREDIT</option>         
        </select>        
      </div>
       
        {/*<div class="col">
          <input type="text" class="form-control" placeholder="Last name" aria-label="Last name"/>
        </div>*/}
      </div>
      {/*<div className="row">
        
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Reference Number</span>        
          <input type="text" aria-label="Ref" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, paymentReference: e?.target?.value})}

          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Transaction Id</span>        
          <input type="text" class="form-control"  aria-label="tranId"
            onChange={(e)=>setFilteredData({...filteredData, tranId: e?.target?.value})}

          />
        </div>
      </div>
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">Account Number</span>        
          <input type="text" aria-label="First name" class="form-control"
            onChange={(e)=>setFilteredData({...filteredData, acctNum: e?.target?.value})}
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">Email</span>        
          <input type="email" class="form-control"  aria-label="Email"
             onChange={(e)=>setFilteredData({...filteredData, createdEmail: e?.target?.value})}
          />
        </div>
      </div>
      <div className="row">
      
        <div class="input-group mb-3 col">
         <span class="input-group-text" id="basic-addon3">From</span>        
          <input type="date" aria-label="Date" class="form-control"
            onChange={(e)=>setFilteredDate({...filteredDate, fromdate: e?.target?.value})}
            required
          />
        </div>
        <div class="input-group mb-3 col">
          <span class="input-group-text" id="basic-addon3">To</span>        
          <input required type="date" class="form-control" placeholder="Date From" aria-label="Date from"
             onChange={(e)=>setFilteredDate({...filteredDate, todate: e?.target?.value})}
          />
        </div>
      </div>*/}
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary"
        >Search</button>
      </div>
      </form>
      </div>

         
          
        <div style={{ height: 800, width: "100%" }}>
        {usersData?.length > 0 && <DataGrid 
         className={styles.table}
         density="comfortable"
        rows={usersData}
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            paginationMode="server"
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={metaData}       
        />}
  </div>
     
  {showViewDocModal ? (
        <ViewDocModal
          showModal={showViewDocModal}
          hideModal={setShowViewDocModal}
          data={viewData}
        />
      ) : (
        ""
      )}
      {showReason ? (
        <Reason
          showModal={showReason}
          hideModal={setShowReason}
          handleSubmit={() => approveReject(selectedId, 'REJECTED')}
          reason={reason}
          setReason={setReason}
        />
      ) : (
        ""
      )}
      </Container>
      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={usersData} file={"KYC users tier "} />
      }
      </>
      )}
    </Layout>
  );
};
export default KycMainPage;
const gridStyle = {
  width: "23%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "10px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-between",
  marginBottom: "30px",
};
