import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { Button } from "antd";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { customBaseUrl, httpPut } from "../../../../store/http";
import { hideLoader, showLoader } from "../../../../utils/loader";
import useMatStyles from "../style";
const EditAggregatorCommission = ({
  showModal,
  setShowModal,
  setCommissionData,
  commissionData,
  handleSubmit,
  commissionsList,
  getCommissions
}) => {
  const styles = useMatStyles();

  const [customAmount, setCustomAmount] = useState('')
  const [postData, setPostData] = useState({});

  useEffect(()=>{
    setPostData(commissionData);
  },[])
 
  const handleUpdate =async () =>{
    
    showLoader();
    const url = `${customBaseUrl.referralUrl}/api/v1/admin/update/commission/${postData.id}`;
    const response = await httpPut(url, postData);
    if (response.status === true) {
      setShowModal(false);
      Swal.fire('Done', response.message)
      getCommissions();
      hideLoader();
    } else {
      Swal.fire('Error', response.message)
      hideLoader();
    }
  }
  const whoToCr = [
    "REFERRAL", "CUSTOMER", "AGGREGATOR_L1", "AGGREGATOR_L2", "AGGREGATOR_L3", "AGGREGATORS", "AGGREGATORS_AND_REFERRAL", "ALL"
  ]
  const commissionType = [
    'SIGNUP_COMMISSION',
    'WITHDRAWAL_COMMISSION',
    'TRANSFER_COMMISSION',
    'AIRTIME_TOPUP_COMMISSION',
    'DATA_TOPUP_COMMISSION',
    'CABLE_COMMISSION',
    'UTILITY_COMMISSION',
    'BETTING_COMMISSION',
    'BILLS_PAYMENT_COMMISSION_AGGREGATOR',
    'WITHDRAWAL_COMMISSION_AGGREGATOR']

  return (
    <>
      <Dialog  open={showModal} onClose={() => {setShowModal(false); setPostData({})}}>
        <DialogTitle>Edit Specific Commission</DialogTitle>
        <DialogContent>
          <div className="row">
            <div className="col-md-12">
            <div style={{ marginTop: "15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount</div>
                <input
                  value={postData.amount}
                  onChange={(e)=>{setPostData({...postData, amount: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount Earned by Waya</div>
                <input
                  value={postData.amountEarnedByWaya}
                  onChange={(e)=>{setPostData({...postData, amountEarnedByWaya: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Amount Type</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    amountType: e.target.value,
                  })}
                  value={postData.amountType}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>  
                  <option value='FIXED'>FIXED</option>
                  <option value='PERCENTAGE'>PERCENTAGE</option>               
                </select>
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Capped Amount</div>
                <input
                  value={postData.cappedAmount}
                  onChange={(e)=>{setPostData({...postData, cappedAmount: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Transaction Count</div>
                <input
                  value={postData.transactionCount}
                  onChange={(e)=>{setPostData({...postData, transactionCount: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage for Super Aggregator</div>
                <input
                  value={postData.percentageAggregator}
                  onChange={(e)=>{setPostData({...postData, percentageAggregator: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage for Aggregator L1</div>
                <input
                  value={postData.percentageAggregatorL1}
                  onChange={(e)=>{setPostData({...postData, percentageAggregatorL1: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage for Aggregator L2</div>
                <input
                  value={postData.percentageAggregatorL2}
                  onChange={(e)=>{setPostData({...postData, percentageAggregatorL2: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Percentage for Aggregator L3</div>
                <input
                  value={postData.percentageAggregatorL3}
                  onChange={(e)=>{setPostData({...postData, percentageAggregatorL3: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>percentage for Customer</div>
                <input
                  value={postData.percentageCustomer}
                  onChange={(e)=>{setPostData({...postData, percentageCustomer: e?.target.value})}}
                  className={`${styles.inputInput} border`}
                />                  
              </div>
              
              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Commission Type</div>
                <input disabled
                  value={postData.name}
                  className={`${styles.inputInput} border`}
                />
              </div>

              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Customer Type</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    customerType: e.target.value,
                  })}
                  value={postData.customerType}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>  
                  <option value='PERSONAL'>PERSONAL</option>
                  <option value='CORPORATE'>CORPORATE</option>
                  <option value='ALL'>ALL</option>               
                </select>
              </div>

              <div style={{ marginTop: "-15px" }} className={styles.inputCont}>
                <div className={styles.inputLabel}>Who to Credit</div>
                <select
                  onChange={(e) => setPostData({
                    ...postData,
                    whoToCredit: e.target.value,
                  })}
                  value={postData.whoToCredit}
                  className={`${styles.inputInput} border`}
                >
                  <option  selected>...</option>  
                  {whoToCr?.map(e=>
                  <option value={e}>{e}</option>
                  )}
                </select>
              </div>
              <div style={{
                  alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
              }} className={styles.inputCont}>
                <Button onClick={handleUpdate} className={styles.filledButton}>
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default EditAggregatorCommission;
