import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import Swal from "sweetalert2";
import { customBaseUrl, httpPost } from "../../../store/http";
import { getArraryString, transformName } from "../../../utils/helper";
import { hideLoader, showLoader } from "../../../utils/loader";
import useStyles from "../style";
const ChangeRoleModal = ({
  showModal,
  setShowModal,
  roles,
  handleChangeRole,
  getAdminUsers
}) => {
  console.log(showModal);
  const styles = useStyles();

  const [rolesChecked, setRolesChecked] = useState(roles);

  const [userRoles, setUserRoles] = useState(showModal.roles);
  const [adminRoles, setAdminRoles] = useState(localStorage.getItem('roles'))
  const [roleId, setRoleId] = useState('')
  useEffect(() => {
   // alert(JSON.stringify(localStorage.getItem('roles')))
    console.info('checked',rolesChecked);
    console.info(userRoles);
  }, []);
  const handleToggle =  (value) => async() => {
    const current = await userRoles.findIndex((item) => item == value.name);
    if(value.name==='ROLE_ADMIN_SUPER' && !adminRoles.includes('ROLE_ADMIN_OWNER') ) return  NotificationManager.error("error",'Not Authorize');
    let newChecked = [...userRoles];
    if (current == -1) {
      let success = await handleChangeRole(showModal.userId, value.name, true);
      if (success  === true) {
        newChecked.push(value.name);        
            console.log('new', newChecked)

      }
      
    } else {
      
      let success = await handleChangeRole(showModal.userId, value.name, false);
      if(success){
        newChecked.splice(current, 1);
      }
    }
    console.log('new', newChecked)
    setUserRoles(newChecked);
   
  };

  const onHideModal = () => {
    getAdminUsers();
    const role = { ...showModal };
    role.isVisible = false;
    Object.apply(role);
    setShowModal(role);
  };


  const handleSubmit =async()=>{
    if(roleId==='ROLE_ADMIN_SUPER' && !adminRoles.includes('ROLE_ADMIN_OWNER') ) return  NotificationManager.error("error",'Not Authorize');

    showLoader();
    const response = await httpPost(`${customBaseUrl.authUrl}/api/v1/admin/manage-user/${showModal.userId}/roles/${roleId}`);
    hideLoader();
    if (!response || response?.error !== undefined || response?.status == false) {
      NotificationManager.error("error",response?.message);
    }
    else{
      const role = { ...showModal };
      role.isVisible = false;
      Object.apply(role);
      setShowModal(role);
      Swal.fire({
        title: "Success",
        text: "Role has been changed",
        icon: "success",
        confirmButtonText: "Ok",
      });
      getAdminUsers();
    }

  }
  return (
    <Modal
      className={styles.modal}
      show={showModal.isVisible}
      onHide={onHideModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header
        style={{
          borderBottom: "none",
          padding: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        closeButton
      >
        <span
          style={{
            fontWeight: "600",
            fontsize: "14px",
            lineHeight: "24px",
            textAlign: "center",
            width: "100%",
            color: "#000",
          }}
        >
          {" "}
          Change Role{" "}
        </span>
        <br></br>
      </Modal.Header>

      <Modal.Body>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          Change
          <span
            style={{
              fontsize: "18px",
              lineHeight: "24px",
              fontWeight: "600",
              textAlign: "center",
              width: "100%",
              color: "#6c757d",
            }}
          >
            {" "}
            {transformName(showModal.firstName + " " + showModal.lastName)}
          </span>
          's Role
        </div>
        <div className={styles.modalBody}>
          {/*<div className={styles.inputCont}>
            <div className={styles.inputLabel}>Select Role</div>
            <div className="row">
              {rolesChecked.length
                ? rolesChecked?.map((value, index) => {
                    return (
                      <div className="col-md-6">
                        <ListItem key={index} disablePadding>
                          <ListItemButton
                            role={undefined}
                            onClick={handleToggle(value)}
                            dense
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={
                                  userRoles.find(
                                    (role) => role === value.name
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": value.description,
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={value.id}
                              primary={transformName(
                                getArraryString([value.description])
                              )}
                            />
                          </ListItemButton>
                        </ListItem>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>*/}
          <div style={{ position: 'relative' }} class="col-md-12 mt-4">
          
          <select value={roleId} onChange={(e)=>{setRoleId(e.target.value)}}   class='form-control '
            id='validationCustom03'>
            <option value="">Select New Role For This User</option>
            {roles?.map((e,i)=>
                <option value={e?.name}>{transformName(
                  getArraryString([e?.name]))}</option>
            )}
          </select>
       
        </div>
        <div class='col-12 text-end mt-5'>
          <button class='btn btn-danger col-12' type='submit' onClick={handleSubmit}>
            Submit
          </button>
        </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChangeRoleModal;
