import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { Modal } from 'antd';
import NaijaStates from 'naija-state-local-government';
import '../../../index.scss';

const Reason = ({ showModal, hideModal, selectedRow, reason, setReason, className, handleSubmit }) => {
  const [data, setData] = useState([]);

  const states = NaijaStates.states();

  return (
    <Modal
      className={className}
      title='Reason'
      centered
      visible={showModal}
      onOk={() => hideModal(false)}
      onCancel={() => hideModal(false)}
      width={600}
      footer={0}
    >
      {/* <form class='row g-3 needs-validation' novalidate> */}

        <div className='col-10 mt-2 mb-3 mx-auto'>
          <div class='input-group'>
            <textarea
              required
              value={reason}
              class='form-control'
              id='inputGroupFile02'
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>

        <div class='col-12 text-center mt-2'>
          <button class='btn btn-primary' type='submit' onClick={handleSubmit}>
            Reject
          </button>
        </div>
    </Modal>
  );
};

export default Reason;
