import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Row, Input, Space, Badge } from "antd";
import qs from "qs";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Layout from "../../components/Layout";
import noDataLogo from "../../assets/images/no-data.png";
import color from "../../theme/color";
import { customBaseUrl, httpDelete, httpGet, httpPost } from "../../store/http";
import {
  Box,
  Select,
  Grid,
  Paper,
  MenuItem,
  FormControl,
  InputBase,
  Stack,
} from "@mui/material";
import { useMatStyles } from "./style";
import { hideLoader, showLoader } from "../../utils/loader";
import { useTheme } from "styled-components";
import ExportData from "../WayaOfficial/modal/exportData";
import AntDropdown from "../../components/CustomDropdown/AntDropdown";
import GeneratedQr from "./generatedQrModal";
const ManageMarketer = ({setShowManageMarkert, selectedMarketer}) => {
  const theme = useTheme();
  const matclasses = useMatStyles({ theme });
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [showSendUser, setShowSendUser] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
  });
  const [searchData, setSearchData] = useState({
    searchText: "",
    searchedColumn: 0,
  });
  const [selectedId, setSelectedId] = useState(null);
  const [date, setDate] = useState(new Date());
  const styles = useMatStyles();
  const [search, setSearch] = useState({
    searchBy: "status",
    searchText: "",
  });
  const [allTransfers, setAllTransfers] = useState(0);
  const [retrievedTransfers, setRetrievedTransfers] = useState(0);
  const [nonRetrievedTransfers, setNonRetrievedTransfers] = useState(0);
  const [allTransactions, setAllTransactions] = useState([]);
  const [showEditDays, setShowEditDays] = useState(false);
  const [reversalDays, setReveversalDays] = useState([]);
  const [selectedRow, setSelectedRow] = useState({})
  const [qrData, setQrData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const getTransactions = async (page, size) => {
    setAllTransactions([]);
    showLoader();
    const pages = page ? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    
    let request = await httpGet(
      `${customBaseUrl.qrCodeUrl}/api/v1/qrcode/qrassigned-marketer/${selectedMarketer.userId}`
    );
    if (request?.status !=false) {
        console.log('dtaa',request.body);
        setAllTransactions(request?.body);
        //setAllTransfers(request.data.totalItems);
        hideLoader();

      }else{
        hideLoader();
        console.log(request)
      }
    
  };

  

  useEffect(() => {
    getTransactions();
}, []);

const modalCloseAction = () => {
  setOpenModal(false);
  //setOpenGenQr(false);
}

  const handleChangeRowsPerPage = async (value) => {
    console.log(value);
    setPagination({ ...pagination, current: 0, pageSize: value });
    await getTransactions(0, value);
  };

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage });
    await getTransactions(newPage, pagination.pageSize);
  };


  const renderDetailsButton = (params) => {
    const handleModal = (qrValue, row) => {
      setOpenModal(true);
      setQrData(qrValue);
      setSelectedRow(row);
    };
    return (
      <strong>
        <Button
          key={params?.row?.action?.id}
          variant="contained"
          size="small"
          style={{ 
            width: '120px',
            backgroundColor: '#FFF1EC',
            border: '1px solid #FF4B01',
            color: '#FF4B01',
          }}
          // onClick={() => setOpenModal(true)}
          onClick={() => {
            handleModal(params?.row?.qrCode, params?.row?.action)
            // setQrData(params?.row?.action?.qrCode)
          }}
        >
          View QR Code
        </Button>
      </strong>
    );
  };
  var searchInput = useRef({});
  const columns = [
    
    { field: 'fullName',
      headerName: 'Full Name',
      width: 250 ,
      headerClassName: styles.tableHeader,     

    },
   
    { field: "phoneNumber",
    headerName: "Phone",    
    width: 250,
    headerClassName: styles.tableHeader,
   

    },
    { field: 'email',
    headerName: "Email",
    width: 250,
    headerClassName: styles.tableHeader,   

    }, 
    { field: 'revenue',
    headerName: "Revenue",
    width: 150,
    headerClassName: styles.tableHeader,    

    }, 
    { field: 'city',
    headerName: "City",
    width: 150,
    headerClassName: styles.tableHeader,
    }, 
    { field: 'createdAt',
    headerName: "Date created",
    width: 200 ,
    headerClassName: styles.tableHeader,  
    },
    {
      field: 'action',
      headerName: 'ACTION',
      headerClassName: 'super-app-theme--header',
      width: 160,
      renderCell: renderDetailsButton,
      headerClassName: styles.tableHeader,  
      disableClickEventBubbling: true,    
    },

  ];

  
  return (
    <>
<div
          className=""
          style={{ cursor: "pointer" }}
          onClick={() => setShowManageMarkert(false)}
        >
          <span className="me-1">
            <ArrowLeftOutlined />
          </span>
          back
        </div>
        <div style={{flexDirection: 'row'}}>
        <select
          className={styles.selectType}
          defaultValue="disabled"
          onChange={(event) => {
            setSearch({ ...search, searchBy: event.target.value });
          }}
        >
          <option value="disabled" disabled>
            Search By...
          </option>
          {columns
            .filter((x) => x.field !== ("totalCount"))
            .map((header) => (
              <option value={header.field}>
                {(header.headerName)}
              </option>
            ))}
        </select>
        <div className={styles.searchRow}>
        <Input
            className={styles.searchInput}
            placeholder="Search Users by Name, Email or Date"
            onChange={(event) => {
              setSearch({ ...search, searchText: event.target.value });
            }}
            prefix={<SearchOutlined className={styles.searchIcon} />}
          />
          </div>
          
          

      </div> 

<div style={tool}>
        <div className="cards-container">
          <div className="cards">
            <p>Total Onboarded Users</p>
            <h2>{allTransactions?.length}</h2>
          </div>
          </div>
          <div className="cards-container">
          <div className="cards">
          <p>Total Revenue</p>
            <h2>{0}</h2>
          </div>
          </div>
        </div>
           


      <Grid
      getRowId ={row=>row.userId}
        style={{ marginTop: "10px" }}
        container
        spacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      ></Grid>
      
      {allTransactions?.length ? (
        <div style={{ height: 800, width: "100%" }}>
        <DataGrid 
        getRowId={row=>row.userId}
         className={styles.table}
         density="comfortable"
        rows={search.searchText?allTransactions.filter(e=>e?.email?.toLowerCase().includes(search.searchText.toLowerCase()) || e?.fullName?.toLowerCase().includes(search.searchText.toLowerCase()) || e?.phoneNumber?.toLowerCase().includes(search.searchText.toLowerCase()) || e?.createdAt?.toLowerCase().includes(search.searchText.toLowerCase()))  :allTransactions }
        
            columns={columns}
            page={pagination.current}
            pageSize={pagination.pageSize}
            onPageSizeChange={(newPageSize) =>
              handleChangeRowsPerPage(newPageSize)
            }
            onPageChange={(newPage) => handleChangePage(newPage)}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            components={{ Toolbar: GridToolbar }}
            rowCount={allTransfers}

        />
  </div>
      ) : (
        <>
          <Row
            type="flex"
            justify="center"
            align="middle"
            className="mt-5"
            style={{ minHeight: "40vh" }}
          >
            <img src={noDataLogo} alt="nodata" style={{ height: "300px" }} />
          </Row>
          <h4 className="mt-3 text-center">No Request yet</h4>
          <p className="mt-3 text-center" style={{ color: color.textGray }}>
            Request will appear here when you
          </p>
        </>
      )}

      
      {openModal && <GeneratedQr modalState={openModal} modalCloseAction={modalCloseAction } qrValue={qrData} selectedRow={selectedRow}/>}

      {showExport &&
         <ExportData showModal={showExport} hideModal={setShowExport} dataList={allTransactions}  />
      }
  
     
    </>
  );
};

const gridStyle = {
  width: "40%",
  textAlign: "left",
  backgroundColor: "#fff",
  padding: "24px 10px 24px 12px",
  fontWeight: "bold",
  marginLeft: "50px",
};

const tool = {
  display: "flex",
  borderRadius: "250px",
  justifyContent: "space-around",
  marginBottom: "30px",
};

export default ManageMarketer;
